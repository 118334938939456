import React from "react"
import styled from "styled-components"
import { graphql } from 'gatsby'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax'; 
import Layout from '../components/layout/layout'
import Head from '../components/general/head'
import { isBrowser, PageContainer, ContentWrapper, HeadingWrapper, Title, Line, SubTitle, SolidBackground, TextureBackground, Container } from '../components/general/general'
import banner from '../images/index/banner.jpg';
import { palette } from "../styles/variables.js"
import { deviceMax } from "../styles/media"
import { useWindowSize } from "../components/general/window"


// Styled Components

const Background = styled.div`
    position: relative;
    z-index: -1;
    width: 100%;
    height: 40rem;
    background-image: url(${banner});
    background-size: cover;
    margin-bottom: -38rem;
 `;
const Overlay = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 15, 41, 0.8);
`;
// const NameWrapper = styled.div`
//     position: relative;
//     width: 100%;
//     margin-bottom: 4rem;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
// `;
const TitleAlt = styled(Title)`
    @media ${deviceMax.laptop} {
        font-size: 6rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 5rem;
    } 
`;
// const Line = styled.div`
//     width: 65%;
//     opacity: .8;
// `;
const SubTitleAlt = styled(SubTitle)`
    @media ${deviceMax.laptopL} {
        font-size: 2.5rem;
    }
    @media ${deviceMax.laptop} {
        font-size: 2rem;
    } 
    @media ${deviceMax.tablet} {
        font-size: 1.5rem;
    } 
    
`;
// const SolidBackgroundAlt = styled(SolidBackground)`
//   &&& {
//     clip-path: polygon(0 0, 100% 2rem, 100% 100%, 0 100%);
//   }
// `;
const ContainerAlt = styled(Container)`
    margin-top: 2rem;
`;
const GoalCard = styled.div`
    max-width: 1072px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
    padding: 2rem 1rem;
    background: #1F2239;
    border-radius: 5px;
    filter: drop-shadow(0px 20px 50px rgba(0,0,0,1));
    &.left {
        flex-direction: row;
    }
    &.right {
        flex-direction: row-reverse;
    }

    @media ${deviceMax.tablet} { 
        justify-content: center;
        align-items: center;
        
        &.left {
            flex-direction: column-reverse;
        }
        &.right {
            flex-direction: column-reverse;
        }
    }
    @media ${deviceMax.mobileL} {
        margin-bottom: 3rem;
        padding: 1rem .5rem;
        filter: drop-shadow(0px 0px 0px rgba(0,0,0,1));
    }

`;
const Copy = styled.div`
    /* width: 60%; */
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${deviceMax.laptop} { 
        width: auto;
    }
`;
const Images = styled.div`
    position: relative;
    /* width: 35%; */
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${deviceMax.laptop} { 
        width: auto;
        margin-bottom: 3rem;
    }
`;
const GoalTitle = styled.h3`
    font-family: "Raleway";
    font-size: 1.6rem;
    font-weight: 800;
    text-transform: uppercase;
    color: ${palette.text90};
`;
const GoalDescription = styled.p`
    font-family: "Alegreya Sans";
    font-size: 1.1rem;
    text-align: justify;
    text-justify: inter-word;
    color: ${palette.text90};
`;
const ImageCircleWrap = styled.div`
    border-radius: 50%;
    
    /* -webkit-border-radius: 50%; */
    /* overflow: hidden; */
    border: 2px solid red;
`;
const PrimaryImage = styled(props => <GatsbyImage {...props} />)`
    width: 18rem;
    height: 18rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid ${palette.warmWhite};
    overflow: hidden;
    @media ${deviceMax.laptop} { 
        width: 16rem;
        height: 16rem;
    }
`;
const SecondaryImage = styled(props => <GatsbyImage {...props} />)`
    width: 8rem;
    height: 8rem;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid ${palette.warmWhite};
    position: absolute;
    bottom: 0;
    &.left {
        left: 0;
    }
    &.right {
        right: 0;
    }
    @media ${deviceMax.laptop} { 
        width: 7rem;
        height: 7rem;
    }
`;


// componentDidMount() {

//     if (typeof window !== `undefined`) {
//     const { width } = useWindowDimensions();
// }
// }


const GoalsPage = (props) => {

    let window = useWindowSize()
    const width  = window[0]

    return (
        <Layout>
            <Head title="Our Goals"/>
            <PageContainer>
                <ContentWrapper>
                    <ParallaxProvider>
                        <Parallax
                            y={[-50, 50]}
                            slowerScrollRate
                            disabled={width <= 1024 }
                        >
                            <Background>
                                <Overlay />
                            </Background>
                        </Parallax>
                        <Parallax
                        y={[100, 0]}
                        fasterScrollRate
                        disabled={width <= 1024 }
                        >
                            <HeadingWrapper>
                                <TitleAlt>{props.data.contentfulOurGoals.title}</TitleAlt>
                                <Line>
                                    <StaticImage
                                        src="../images/lines/thick-line.png"
                                        alt="Single Heavy Weight Line"
                                        layout="constrained"
                                        // css={`
                                        //     width: 65%;
                                        //     opacity: .8;
                                        // `}                                       
                                    />
                                </Line>
                                <SubTitleAlt>{props.data.contentfulOurGoals.subtitle}</SubTitleAlt>
                            </HeadingWrapper>
                        </Parallax>
                        <Parallax disabled={width <= 1024 }>
                            <SolidBackground>
                                <TextureBackground>
                                <ContainerAlt>
                                    {props.data.contentfulOurGoals.goals.map((goal) => {
                                        return (
                                            <GoalCard className={goal.theme}>
                                                <Copy>
                                                    <GoalTitle>{goal.goalTitle}</GoalTitle>
                                                    <GoalDescription>{goal.goalDescription.goalDescription}</GoalDescription>
                                                </Copy>
                                                <Images>
                                                    {/* <ImageCircleWrap> */}
                                                        <PrimaryImage
                                                            image={goal.primaryImage.gatsbyImageData}
                                                            alt={goal.primaryImage.description}
                                                            aspectRatio={1}
                                                        
                                                        />
                                                    {/* </ImageCircleWrap> */}
                                                    <SecondaryImage className={goal.theme}
                                                        image={goal.secondaryImage.gatsbyImageData}
                                                        alt={goal.secondaryImage.description}
                                                        aspectRatio={1}
                                                        
                                                    />

                                                </Images>
                                            </GoalCard>
                                        )
                                    })}
                                </ContainerAlt>
                                </TextureBackground>
                            </SolidBackground>
                        </Parallax>
                    </ParallaxProvider>
                </ContentWrapper>
            </PageContainer>
        </Layout>
    )
}

export const query = graphql`
    query {
        contentfulOurGoals {
            title
            subtitle
            goals {
                goalTitle
                goalDescription {
                    goalDescription
                }
                theme
                displayOrientation
                primaryImage {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
                secondaryImage {
                    gatsbyImageData (
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                    )
                    description
                }
            }
        }
    }
`

export default GoalsPage